<template>
  <div class="supper">
    <div class="holder">
      <Header />
      <!-- <div v-if="loader" class="loader-div pt-3 pb-3 m-auto">
        <i class="fa fa-spinner fa-spin fa-2x ml-3 f-12  btn-loader"> </i>
      </div> -->
      <div v-if="loader">
        <PageLoader></PageLoader>
      </div>
      <div v-if="!loader" class="container">
        <div class="blog_wrap">
          <h3 class="blog_title" v-html="theblog.title"></h3>
          <img class="blog_image" :src="theblog.images[0].imageurl" alt="" />
          <p class=" container blog_body" v-html="theblog.body"></p>
        </div>

        <div class="share">
          <span @click="stopUnder($event)">Share</span>
          <nav>
            <ShareNetwork
              network="twitter"
              url="https://myshelta.com/blog/2110227304"
              v-bind:title="blogTitle"
              v-bind:description="blogBody"
              quote="Win while living"
              hashtags="Shelta"
            >
              <i class="fa fa-twitter"> </i>
            </ShareNetwork>
            <ShareNetwork
              network="facebook"
              url="https://myshelta.com/blog/2110227304"
              v-bind:title="blogTitle"
              v-bind:description="blogBody"
              quote="Win while living"
              hashtags="Shelta"
              ><i class="fa fa-facebook"></i
            ></ShareNetwork>
            <ShareNetwork
              network="whatsapp"
              url="https://myshelta.com/blog/2110227304"
              v-bind:title="blogTitle"
              v-bind:description="blogBody"
              quote="Win while living"
              hashtags="Shelta"
              ><i class="fa fa-whatsapp"></i
            ></ShareNetwork>
            <ShareNetwork
              network="linkedin"
              url="https://myshelta.com/blog/2110227304"
              v-bind:title="blogTitle"
              v-bind:description="blogBody"
              quote="Win while living"
              hashtags="Shelta"
              ><i class="fa fa-linkedin"></i
            ></ShareNetwork>
          </nav>
        </div>
      </div>
    </div>
    <FooterMain />
  </div>
</template>

<script>
import PageLoader from "./PageLoader.vue";
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import _ from "lodash";
import { Service } from "../store/service";
import { Blog } from "./Blog";
const Api = new Service();

export default {
  name: "BlogRead",
  // props: { code: ''},
  components: {
    Header,
    FooterMain,
    PageLoader
  },
  data() {
    return {
      loader: false,
      theblog: "",
      id: "",
      title: "",
      blogTitle: "",
      blogBody: "",
      // blogid: '',
      theblog: {
        images: [0]
      }
    };
  },
  mounted() {
    // this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    // this.blogid = this.$route.params.id;
    // console.log('Code', this.code);
    this.readBlog();
    // Force a hover to see the effect
    const share = document.querySelector(".share");

    setTimeout(() => {
      share.classList.add("hover");
    }, 1000);

    setTimeout(() => {
      share.classList.remove("hover");
    }, 3000);
  },
  methods: {
    stopUnder: function(event) {
      console.log("Event", event);
      event.stopPropagation();
    },
    readBlog: async function() {
      this.loader = true;
      const blogid = this.$route.params.id;
      try {
        await Api.getRequestNoAuth(`fetchblogarticlebycode/${blogid}`)
          .then(res => {
            this.loader = false;
            console.log("REad blog", res);
            this.theblog = res.data.blog;
            this.blogTitle = this.theblog.title.replace(/(<([^>]+)>)/gi, "");
            this.blogBody = this.theblog.body.replace(/(<([^>]+)>)/gi, "");
            // this.blogTitle = this.theblog.title.replace(/(<([^>]+)>)/gi, "");
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

$backgroundColor: #e5eef3;
$buttonColor: #ffffff;
$textColor: #f1ce64;
$hoverColor: #fff;
$facebook: #3b5998;
$twitter: #61c5ec;
$google: #ea4335;
$github: #000000;
$whatsapp: #128c7e;
$linkedin: #007bb5;

.supper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  //    background: url("../assets/images/comingsoon.svg") no-repeat center center;
  // min-height: 100vh;
}

.loader-div {
  margin: auto;
  padding-top: 13rem !important;
}

.blog_wrap {
  margin-top: 8rem;
}

.blog_title {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 131.2%;
  text-align: center;
  color: $secondary;
  padding-bottom: 1rem;
}
.blog_image {
  width: 100%;
  // max-width: 1196px;
  max-width: 100%;
  border-radius: 4px;
  height: auto;
  max-height: 100%;
  // max-height: 500px;
  padding-bottom: 2rem;
}

.blog_body {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 246.2%;
  align-items: center;
  color: $secondary;
  padding: 0 8rem;
  text-align: justify;
  padding-bottom: 2rem;
}

.share {
  // position: absolute;
  // width: 400px;
  // left: 50%;
  // margin-left: -200px;
  // top: 50%;
  // margin-top: -40px;
  display: contents;
  border-radius: 80px;

  span {
    width: 200px;
    line-height: 55px;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }

  nav {
    font-size: 0;
  }

  a {
    font-family: Gotham;
    line-height: 55px;
    width: 55px;
    text-align: center;
    display: inline-block;
    background-color: $yellow;
    color: $secondary;
    overflow: hidden;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    margin: 0 -15px;
    // margin: 0 -20px;
    box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.1);

    &:nth-child(1) {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      margin-left: 0;

      &:hover {
        background-color: $twitter;
      }
    }

    &:nth-child(2):hover {
      background-color: $facebook;
    }

    &:nth-child(3):hover {
      background-color: $whatsapp;
    }

    &:nth-child(4) {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      margin-right: 0;

      &:hover {
        background-color: $linkedin;
      }
    }
  }

  &:hover,
  &.hover {
    span {
      opacity: 0;
    }

    a {
      border-radius: 50%;
      margin: 0 10px;
      color: $secondary;
      font-size: 26px;

      &:hover {
        color: $hoverColor;
      }
    }
  }
}

@media screen and (min-device-width: 300px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .blog_wrap {
    margin-top: 6.5rem;
  }
  .blog_title {
    font-size: 26px;
    text-align: left;
    padding-top: 2rem;
  }
  .blog_body {
    padding: 0;
    text-align: left;
    padding-bottom: 5rem;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .blog_title {
    text-align: left;
    font-size: 40px;
  }
  .blog_body {
    padding: 0 2rem;
  }
}
</style>
